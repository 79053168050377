import Vue from 'vue'
import VueRouter from 'vue-router'
import HostedPaymentView from '../views/HostedPaymentView'
import failedView from '../views/failedView'
import newHostedPaymentView from '../views/newHostedPaymentView'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Hosted Payment Page',
    component: HostedPaymentView,
    meta: {
      title: 'Hosted Payment Page'
    }
  },
  {
    path: '/response_failure',
    name: 'Payment Failed',
    component: failedView
  },
  {
    path: '/new',
    name: 'Hosted Payment Page',
    component: newHostedPaymentView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
