<template>
    <div class="home">
      <failed></failed>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import failed from '../components/failed.vue';
  
  export default {
    name: 'failedView',
    components: {
        failed
    }
  }
  </script>