<template>
    <div class="home">
      <new-hosted-payment-page></new-hosted-payment-page>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import newHostedPaymentPage from '../components/newHostedPaymentPage.vue';
  
  export default {
    name: 'HostedPaymentView',
    components: {
        newHostedPaymentPage
    }
  }
  </script>