<template>
    <v-app class="card_container">
        <div v-if="invoiceId === ''" class="card">
            <img src="../assets/process_failure.png" alt="" class="logo">
            <p class="invoice_error">Invoice ID not provided</p>
        </div>
        <div v-else-if="paid" class="card">
            <img src="../assets/process_success.png" alt="" class="logo">
            <p class="invoice_paid">Invoice already Paid</p>
        </div>
        <v-row class="no-margin" v-else>
            <v-col cols="8">
                <div class="card_data">
                    <img src="../assets/logo.png" alt="OnTrac Logo">

                    <v-row>
                        <v-text-field outlined rounded v-model="creditCardOwner" prepend-inner-icon="mdi-account"
                            name="creditCardOwner" label="Credit Card Owner"></v-text-field>
                    </v-row>
                    <v-row>
                        <v-text-field outlined rounded v-model="creditCardNumber" @keyup="formatCreditCardNumber"
                            prepend-inner-icon="mdi-credit-card" name="creditCardNumber" label="Credit Card Number"
                            maxlength="19"></v-text-field>
                    </v-row>
                    <v-row class="gap">
                        <v-text-field outlined rounded v-model="expirationDate" @keyup="formatExpirationDate"
                            name="expirationDate" prepend-inner-icon="mdi-calendar" label="Expiration Date"
                            class="pr-10"></v-text-field>
                        <v-text-field outlined rounded v-model="cvvNumber" @keyup="formatCvvNumber"
                            :type="showCvv ? 'text' : 'password'" @click:prepend-inner="showCvv = !showCvv"
                            :prepend-inner-icon="showCvv ? 'mdi-eye' : 'mdi-eye-off'" name="cvvNumber"
                            label="CVV / CVC"></v-text-field>
                    </v-row>
                    <v-row>
                        <v-text-field outlined rounded v-model="zipCode" prepend-inner-icon="mdi-map-marker"
                            @keyup="formatZipCode" name="zipCode" label="Billing Zip" maxlength="7"></v-text-field>
                    </v-row>
                </div>
            </v-col>
            <v-col cols="4">
                <div class="card_amount">
                    <v-row>
                        <v-col>
                            <p class="Header">Order Form</p>
                            <p class="Header2"><b>Customer:</b> {{ customerName }}</p>
                            <v-row>
                                <v-col>
                                    <p><b>Total</b></p>

                                </v-col>
                                <v-col>
                                    <p class="total">BBD{{ total.toFixed(2) }}</p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <form ref="paymentForm" :action="fiservUrl" method="post" @submit.prevent="handleSubmit()">

                            <input type="hidden" name="hash_algorithm" value="HMACSHA256" />
                            <!-- <input type="hidden" name="checkoutoption" value="combinedpage" /> -->
                            <input type="hidden" name="language" value="en_US" />
                            <input type="hidden" name="hashExtended" value="" ref="hashExtended" />
                            <input type="hidden" name="storename" :value="storename" />
                            <input type="hidden" name="full_bypass" value="false" />
                            <input type="hidden" name="dccSkipOffer" value="false" />
                            <input type="hidden" name="paymentMethod" value="M" />
                            <input type="hidden" name="timezone" :value="timezone" />
                            <input type="hidden" name="txndatetime" :value="txndatetime()" />
                            <input type="hidden" name="txntype" value="sale" />
                            <input type="hidden" name="chargetotal" :value="total" readonly />
                            <!-- <input type="hidden" name="authenticateTransaction" value="false" /> -->
                            <input type="hidden" name="currency" value="840" />
                            <input type="hidden" name="responseFailURL" :value="response_failure_url" />
                            <input type="hidden" name="responseSuccessURL" :value="response_success_url" />
                            <input type="hidden" name="cardnumber" :value="creditCardNumber">
                            <input type="hidden" name="expmonth" :value="exp_month">
                            <input type="hidden" name="expyear" :value="exp_year">
                            <input type="hidden" name="cvm" :value="cvvNumber">
                            <input type="hidden" name="customParam_invoiceId" :value="invoiceId" />
                            <input class='button mx-auto' type="submit" value="PAY" />
                        </form>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    </v-app>
</template>

<script>
import CryptoJS from 'crypto-js';
import moment from 'moment-timezone';
import axios from 'axios';

// Vue.prototype.moment = moment

export default {
    name: 'App',

    components: {
    },

    data: () => ({
        paid: false,
        timezone: "America/New_York",
        hashExtended: "",
        invoiceId: "",
        userId: "",
        paymentType: "",
        total: 0,
        customerName: "",
        storename: process.env.VUE_APP_STORENAME,
        sharedSecret: process.env.VUE_APP_SHAREDSECRET,
        fiservUrl: process.env.VUE_APP_FISERV_ENVIRONMENT_URL,
        firebaseUrl: process.env.VUE_APP_FIREBASE_URL,
        response_failure_url: process.env.VUE_APP_RESPONSE_FAILURE_URL,
        response_success_url: process.env.VUE_APP_RESPONSE_SUCCESS_URL,
        creditCardOwner: "",
        creditCardNumber: "",
        expirationDate: "",
        cvvNumber: "",
        zipCode: "",
        showCvv: false,
        exp_month: "",
        exp_year: ""
    }),
    methods: {
        txndatetime() {
            return moment().tz(this.timezone).format('YYYY:MM:DD-HH:mm:ss');
        },
        handleSubmit() {

            // Payment Form
            const paymentForm = this.$refs.paymentForm;
            // Extract Payment Form Parameters
            const paymentParameters = Array.from(paymentForm.elements)
                .filter(item => item.name && item.value !== "")
                .reduce((obj, item) => {
                    obj[item.name] = item.value;
                    return obj;
                }, {});

            // Prepare Message Signature Content
            const ignoreSignatureParameters = ["hashExtended"];
            const messageSignatureContent = Object.keys(paymentParameters)
                .filter(key => !ignoreSignatureParameters.includes(key))
                .sort()
                .map(key => paymentParameters[key]);

            // Calculate Message Signature
            const messageSignature = CryptoJS.HmacSHA256(messageSignatureContent.join("|"), this.sharedSecret);
            const messageSignatureBase64 = CryptoJS.enc.Base64.stringify(messageSignature);

            // Update Form Parameters
            this.$refs.hashExtended.value = messageSignatureBase64;

            // You can also submit the form if needed
            this.$refs.paymentForm.submit();
        },
        formatCreditCardNumber() {
            // Remove non-numeric characters from the input
            let numericValue = this.creditCardNumber.replace(/\D/g, '');
            // Ensure maximum length is not exceeded
            if (numericValue.length > 16) {
                numericValue = numericValue.slice(0, 16);
            }
            // Format the credit card number based on its length and type
            if (numericValue.length > 0) {
                if (numericValue.length <= 4 || numericValue.length > 16) {
                    // If the length is less than or equal to 4, or greater than 16,
                    // simply assign the numeric value without formatting
                    this.creditCardNumber = numericValue;
                } else if (numericValue.length <= 10) {
                    // For lengths between 5 and 10, insert a space after every 4 characters
                    this.creditCardNumber = numericValue.replace(/(\d{4})/g, '$1 ');
                } else {
                    // For lengths between 11 and 16, insert spaces after every 4 characters
                    // but exclude the last group of characters
                    this.creditCardNumber = numericValue.slice(0, 16).replace(/(\d{4})(?!$)/g, '$1 ');
                }

                // Format for American Express (15 digits)
                if (numericValue.length === 15) {
                    this.creditCardNumber = numericValue.replace(/(\d{4})(\d{6})(\d{5})/, '$1 $2 $3');
                }
            } else {
                // If the input is empty, keep the number as is
                this.creditCardNumber = numericValue;
            }
        },
        formatExpirationDate() {
            let numericValue = this.expirationDate.replace(/\D/g, '');
            this.expirationDate = numericValue;
            if (numericValue.length > 2) {
                const firstTwoDigits = parseInt(numericValue.slice(0, 2), 10); // Convertir los primeros dos dígitos a un número entero
                if (firstTwoDigits <= 12) { // Verificar si los primeros dos dígitos son menores o iguales a 12
                    this.expirationDate = numericValue.slice(0, 2) + '/' + numericValue.slice(2, 4);
                    this.exp_month = numericValue.slice(0, 2);
                    this.exp_year = numericValue.slice(2, 4);
                } else { // Si los primeros dos dígitos son mayores que 12, ajustar a 12
                    this.expirationDate = '12/' + numericValue.slice(2, 4);
                    this.exp_month = '12';
                    this.exp_year = 2000 + numericValue.slice(2, 4);
                }
            }
        },
        formatCvvNumber() {
            let numericValue = this.cvvNumber.replace(/\D/g, '');
            this.cvvNumber = numericValue;
            if (numericValue.length > 4) {
                this.cvvNumber = numericValue.slice(0, 4);
            }
        },
        formatZipCode() {
            this.zipCode = this.zipCode
                .replace(/[^a-zA-Z0-9\s]/g, "")
                .substring(0, 7);
        },
    },
    async mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        this.invoiceId = urlParams.get('invoice_id') || '';
        this.userId = urlParams.get('user_id') || '';
        this.paymentType = urlParams.get('payment_type') || '';
        console.log(this.firebaseUrl);
        try {
            const response = await axios(this.firebaseUrl, {
                method: 'post',
                data: {
                    invoiceId: this.invoiceId,
                    userId: this.userId
                }
            })
            this.total = response.data.data.invoice.total;
            this.customerName = response.data.data.invoice.customer_name;
            this.paid = response.data.data.invoice.balance == 0 ? true : false;
        } catch (err) {
            console.log("err", err);

        }

    }
};
</script>

<style scoped>
.card_container {
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
}

.card_data,
.card_amount {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 40%;
    font-family: "Raleway", sans-serif;
    font-size: large;
    background-color: white;
    border: 0px solid #bacdd8;
    padding: 18px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.card_data {
    width: 90%;
    height: 90vh;
    margin: 20px 20px 0px 10%;
    padding: 50px
}

.card_amount {
    width: 90%;
    height: 90vh;
    margin: 20px 20px 0px 10px;
    padding: 50px;
}

.Header {
    color: #f58220;
    margin: auto;
    padding: 40px;
    font-size: 1.5rem !important;
    text-align: center;
}

.Header2 {
    color: #111010;
    margin: auto;
    padding-bottom: 30px;
}

.no-margin {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

img {
    display: block;
    margin: auto;
}

form {
    margin: auto;
}

.button {
    background-color: #f58220;
    color: white;
    padding: 14px 20px;
    margin-top: 10px;
    border: none;
    cursor: pointer;
    width: 150px;
    border-radius: 12px;
}

.button:hover {
    opacity: 0.8;
}

.total_container {
    border: 2px solid #f58220;
    padding: 10px;
}

.logo {
    width: 150px;
    margin-bottom: 20px;
}

.invoice_error {
    color: red;
    text-align: center;
    font-size: 2rem;
}

.invoice_paid {
    color: green;
    text-align: center;
    font-size: 2rem;
}

.card {
    /* margin: 0 auto !important; */
    /* height: auto; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 40%;
    font-family: "Raleway", sans-serif;
    font-size: large;
    background-color: white;
    border: 0px solid #bacdd8;
    padding: 18px;
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>