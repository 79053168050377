<template>
  <div id="app" class="card_container">
    <router-view/>
    <v-footer class="footer">
      <a href=" https://ontracsystems.com/ontrac-terms-and-conditions-final/">Terms and Conditions</a>
      <a href=" https://ontracsystems.com/home/contact-us/">Contact Us</a>
      <a href="  https://ontracsystems.com/home/contact-us/">Privacy Policy</a>
    </v-footer>
  </div>
</template>

<style>
  a {
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
    color: gray;
    font-size: 1.3rem;
    margin: 20px;
  }
  a:hover {
    color: #f58220 ;
  }
  .footer {
    background-color: #00000055;
    display: flex;
    justify-content: space-between;
    align-content: center;
    height: 4vh;
  }
  #app {
    font-family: Arial, Helvetica, sans-serif;
    background-image:url(./assets/On-Trac-Website-Background-2.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 96vh;
  }
</style>
<script>
export default {
  created() {
    this.updateTitle(); // Actualiza el título cuando se crea el componente
  },
  watch: {
    $route() {
      this.updateTitle(); // Actualiza el título cuando cambia la ruta
    }
  },
  methods: {
    updateTitle() {
      const pageTitle = this.$route.meta.title || 'Mi Aplicación'; // Título predeterminado
      document.title = pageTitle;
    }
  }
}
</script>
