<template>
    <div class="home">
      <hosted-payment-page></hosted-payment-page>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import HostedPaymentPage from '../components/HostedPaymentPage.vue';
  
  export default {
    name: 'HostedPaymentView',
    components: {
        HostedPaymentPage
    }
  }
  </script>